import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PriceSidebar from './PriceSidebar';
import Stepper from './Stepper';
// import {
//     CardNumberElement,
//     CardCvcElement,
//     CardExpiryElement,
//     useStripe,
//     useElements,
// } from '@stripe/react-stripe-js';
import { clearErrors } from '../../actions/orderAction';
import { useSnackbar } from 'notistack';
import { post } from '../../utils/paytmForm';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import MetaData from '../Layouts/MetaData';
import QR from '../../assets/images/UnmeshQR.jpeg'
import { newOrder } from '../../actions/orderAction';
import { emptyCart } from '../../actions/cartAction';
import { Link, useNavigate } from 'react-router-dom';
import './payments.css';

const Payment = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { shippingInfo, cartItems } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.user);
  const { error } = useSelector((state) => state.newOrder);
  const navigate = useNavigate();

  const [payDisable, setPayDisable] = useState(false);

  const totalPrice = cartItems.reduce((sum, item) => sum + (item.price * item.quantity), 0);
  const totalCP = cartItems.reduce((sum, item) => sum + (item.cuttedPrice * item.quantity), 0);

  const order = {
    shippingInfo,
    orderItems: cartItems,
    totalPrice,
    totalCP,
  }

  const [showUpiImage, setShowUpiImage] = useState(false);
  const [paymentOption, setPaymentOption] = useState('paytm');
  const [transactionNo, setTransactionNo] = useState('');
  const [paymentScreenshot, setPaymentScreenshot] = useState(null);

  // ... existing code ...

  const handlePaymentOptionChange = (e) => {
    setPaymentOption(e.target.value);
  };

  const handleTransactionNoChange = (e) => {
    setTransactionNo(e.target.value);
    if (e.target.value == '' || paymentScreenshot == null) setPayDisable(true);
    else setPayDisable(false);
  };

  const handlePaymentScreenshotChange = (e) => {
    // setPaymentScreenshot(e.target.files[0]);

    const file = e.target.files[0];

    const tempFD = new FormData();
    tempFD.append('file', file);
    tempFD.append('upload_preset', 'gdygo7us');
    axios.post('https://api.cloudinary.com/v1_1/dhfh8crr8/image/upload', tempFD)
      .then((res) => {
        const obj = { url: res.data.secure_url, public_id: res.data.public_id, };
        setPaymentScreenshot(JSON.stringify(obj));
        if (e.target.value == null || transactionNo == '') setPayDisable(true);
        else setPayDisable(false);
      })
      .catch(res => console.log("error::", res));
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setPayDisable(true);

    const handlePaymentOptionChange = (e) => {
      setPaymentOption(e.target.value);
      if (e.target.value === 'upi') {
        setShowUpiImage(true);
      } else {
        setShowUpiImage(false);
      }
    };

    const config = {
      headers: {
        'Content-Type': 'application/json',
        // Other headers if needed
      },
      // Other options if needed
    };

    // ... existing code ...

    if (transactionNo == "" || paymentScreenshot <= 0) {
      enqueueSnackbar("Enter correct details!", { variant: "warning" });
      return;
    }

    const formData = new FormData();
    formData.append('shippingInfo', JSON.stringify(shippingInfo));
    cartItems.forEach((s) => {
      formData.append("orderItems", JSON.stringify(s));
    });
    formData.append('totalPrice', totalPrice);
    formData.append('transactionNo', transactionNo);
    formData.append('paymentScreenshot', paymentScreenshot);
    formData.append('totalCP', totalCP);

    try {
      //No need of below since we are not using any third party payment option.
      // const { data } = await axios.post('/api/v1/payment/process', formData, config);

      // ... existing code ...

      order.formData = formData;

      console.log("creating new order");
      dispatch(newOrder(formData));
      // console.log("empting");
      dispatch(emptyCart());

      // console.log("REdirecting");
      navigate("/order/success");

    } catch (error) {
      // ... existing code ...
      console.log("In eeror");
    }
  };

  // ... existing code ...

  return (
    <>
      <MetaData title="MM Coins: Secure Payment | Paytm" />

      <main className="w-full mt-20">
        <div className="flex flex-col sm:flex-row gap-3.5 w-full sm:w-11/12 mt-0 sm:mt-4 m-auto sm:mb-7">
          <div className="flex-1">
            <Stepper activeStep={3}>
              <div className="w-full bg-white">
                <form onSubmit={submitHandler} encType="multipart/form-data" autoComplete="off" className="flex flex-col justify-start gap-2 w-full mx-8 my-4 overflow-hidden">
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="payment-radio-group"
                      value={paymentOption}
                      onChange={handlePaymentOptionChange}
                      name="payment-radio-button"
                    >
                      <FormControlLabel
                        value="upi"
                        onChange={handlePaymentOptionChange}
                        control={<Radio />}
                        label={
                          <div className="flex items-center gap-4">
                            <img draggable="false" className="h-6 w-6 object-contain" src="https://res.cloudinary.com/dhfh8crr8/image/upload/v1691327026/avatars/zlwbxvan4zg7pmr9xd9q.png" alt="UPI Logo" />
                            <span>UPI</span>
                          </div>
                        }
                      />
                      {paymentOption === 'upi' && (
                        <>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <p>Please scan the QR code below and Pay ₹{totalPrice.toLocaleString()}.</p>
                            <img style={{ margin: '16px', alignSelf: 'left' }} height="250px" width="250px" src="https://res.cloudinary.com/dhfh8crr8/image/upload/v1692025578/MMcoinsQR_sza2j4.jpg" alt="UPI Image" />
                            <div style={{ border: '1px solid #ccc', borderRadius: '4px', padding: '8px', width: '65%' }}>
                              <p>Transaction No: {transactionNo}</p>
                              <input
                                type="text"
                                id="transactionNo"
                                value={transactionNo}
                                onChange={handleTransactionNoChange}
                                placeholder="Transaction Number"
                                style={{
                                  width: '100%',
                                  border: '1px solid #ccc',
                                  borderRadius: '4px',
                                  padding: '8px',
                                  outline: 'none',
                                }}
                              />
                            </div>

                            <p style={{ marginTop: '16px' }}>Upload payment proof:</p>
                            <input
                              type="file"
                              id="paymentProof"
                              name="paymentProof"
                              onChange={handlePaymentScreenshotChange}
                              accept="image/*"
                              style={{ width: '65%', marginTop: '8px', padding: '8px', outline: 'none', border: '1px solid #ccc', borderRadius: '4px' }}
                            />
                            Kindly wait after uploading image.
                          </div>
                          {/* <p>Please scan the QR code below and {`Pay ₹${totalPrice.toLocaleString()}`}.</p>
                            <img style={{ margin: '16px' }} align = "middle" height = "250px" width = "250px" src={QR} alt="UPI Image" />
                            <p>Transaction No:</p>
                            <input style={{ width: '65%', border: '1px solid #ccc', borderRadius: '4px', padding: '4px' }} type="text" id = "transactionNo" value={transactionNo} onChange={handleTransactionNoChange} placeholder="Transaction Number" />

                            <p>Upload payment proof:</p>
                            <input type="file" id = "paymentProof" onChange={handlePaymentScreenshotChange} accept="image/*" /> */}
                        </>
                      )}


                      {/* <FormControlLabel
                        value="paytm"
                        control={<Radio />}
                        onChange={handlePaymentOptionChange}
                        label={
                          <div className="flex items-center gap-4">
                            <img draggable="false" className="h-6 w-6 object-contain" src="https://rukminim1.flixcart.com/www/96/96/promos/01/09/2020/a07396d4-0543-4b19-8406-b9fcbf5fd735.png" alt="Paytm Logo" />
                            <span>Paytm - Will be availabele soon</span>
                          </div>
                        }
                      />
                      {paymentOption === 'paytm' && (
                        <>
                          <strong><p>We deeply regret for the inconvinience<bR />Payment via paytm gateway is currently unavailable.<bR />Kindly use manual UPI payment.</p></strong>
                        </>
                      )} */}

                    </RadioGroup>
                  </FormControl>

                  <input type="submit" value={`Pay ₹${totalPrice.toLocaleString()}`} disabled={payDisable ? true : false} className={`${payDisable ? "bg-primary-grey cursor-not-allowed" : "bg-primary-orange cursor-pointer"} w-1/2 sm:w-1/4 my-2 py-3 font-medium text-white shadow hover:shadow-lg rounded-sm uppercase outline-none`} />
                </form>
              </div>
            </Stepper>
          </div>
          <PriceSidebar cartItems={cartItems} />
        </div>
      </main>
    </>
  );
};

export default Payment;