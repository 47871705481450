import StarIcon from '@mui/icons-material/Star';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { getDiscount } from '../../utils/functions';
import { useDispatch, useSelector } from 'react-redux';
import { addToWishlist, removeFromWishlist } from '../../actions/wishlistAction';
import { useSnackbar } from 'notistack';
import { addItemsToCart } from '../../actions/cartAction';
import { Link, useNavigate, useParams } from 'react-router-dom';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const Product = ({ _id, name, images, ratings, numOfReviews, price, cuttedPrice, stock, description , serialNo}) => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const { wishlistItems } = useSelector((state) => state.wishlist);

    const itemInWishlist = wishlistItems.some((i) => i.product === _id);
    const { cartItems } = useSelector((state) => state.cart);
    const itemInCart = cartItems.some((i) => i.product === _id);

    const addToWishlistHandler = () => {
        if (itemInWishlist) {
            dispatch(removeFromWishlist(_id));
            enqueueSnackbar("Remove From Wishlist", { variant: "success" });
        } else {
            dispatch(addToWishlist(_id));
            enqueueSnackbar("Added To Wishlist", { variant: "success" });
        }
    }

    const addToCartHandler = () => {
        dispatch(addItemsToCart(_id));
        enqueueSnackbar("Product Added To Cart", { variant: "success" });
    }

    const goToCart = () => {
        navigate('/cart');
    }

    return (
        <div style={{height:"100%"}} className="flex flex-col items-start gap-2 px-4 py-6 relative hover:shadow-lg rounded-sm">
            {/* <!-- image & product title --> */}
            <Link to={`/product/${_id}`} className="flex flex-col items-center text-center group">
                <div className="w-50 h-50">
                    <img draggable="false" className="w-full h-full object-contain" src={images && images[0].url} alt="" />
                </div>
                <span style={{ fontSize: "18px" }} className="text-sm mt-4 group-hover:text-primary-blue text-left">{serialNo}. {name.length > 85 ? `${name.substring(0, 85)}...` : name}</span>
            </Link>
            {/* <!-- image & product title --> */}

            {/* <!-- product description --> */}
            <div style={{marginBottom: '40px' }} class="grid-container flex flex-col gap-2 items-end w-full">
                <div class="grid-item flex items-center justify gap-1.5 text-md font-medium">
                    <span>{description}</span>
                </div>
                <div class="grid-item flex items-center justify gap-1.5 text-md font-medium">
                    <span style={{ fontSize: "25px" }}>₹{price.toLocaleString()}</span>
                </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems:'flex-end'}} className="flex flex-col gap-2 w-full">
                <div style={{ position: 'absolute', bottom: '0', transform: 'translateX(-50%)', padding: '10px'}} className="flex items-center justify-between gap-1.5 text-md font-medium">
                    {/* Button to add to cart or go to cart */}
                    {stock > 0 && (
                        <div className="w-full">
                            <button onClick={itemInCart ? goToCart : addToCartHandler} className="p-4 w-full flex items-center justify-center gap-2 text-white bg-primary-yellow rounded-sm shadow hover:shadow-lg">
                                <ShoppingCartIcon />
                                {itemInCart ? "CART" : "ADD"}
                            </button>
                        </div>
                    )}

                    {/* OR SOLD Message */}
                    {stock == 0 && <p className="p-4 w-full flex items-center justify-center gap-2 text-white bg-red-600 cursor-not-allowed rounded-sm shadow hover:shadow-lg" style={{ fontWeight: 'bold', fontSize: '16px' }}>SOLD</p>}
                </div>
            </div>

            {/* <!-- wishlist badge --> */}
            <span onClick={addToWishlistHandler} className={`${itemInWishlist ? "text-red-500" : "hover:text-red-500 text-gray-300"} absolute top-6 right-6 cursor-pointer`}><FavoriteIcon sx={{ fontSize: "18px" }} /></span>

        </div>
    );
};

export default Product;
